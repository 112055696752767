import { createSlice } from "@reduxjs/toolkit";
// Function to get user data from localStorage
const getStoredUserData = () => {
  const storedUserData = localStorage.getItem("userData");
  return storedUserData
    ? JSON.parse(storedUserData)
    : {
        id: null,
        role: "",
        name: "",
        email: "",
        profile_pic: "",
        role_id: "",
        address_line1: "",
        address_line2: "",
        city: "",
        company: "",
        country: "",

        phone_number: "",
        state: "",
        current_state: "",
        type_of_business: "",
        zipcode: "",
        plan_id: "",
      };
};

const initialState = {
  userData: getStoredUserData(),
  authToken: localStorage.getItem("authToken"),
  location: {
    lat: "36.7783",
    long: "-119.4179",
    state: "",
  },
};
export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    userDataSet: (state, action) => {
      state.userData = action.payload;
    },
    authTokenSet: (state, action) => {
      state.authToken = action.payload;
    },
    emailSet: (state, action) => {
      state.email = action.payload;
    },
    locationSet: (state, action) => {
      state.location = action.payload;
    },
  },
});

// Export actions generated by createSlice
export const { userDataSet, authTokenSet, emailSet, locationSet } =
  loginSlice.actions;

// Export reducer
export default loginSlice.reducer;
