import { showNotification } from "@mantine/notifications";
import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  params: {},
});

// Set default headers using interceptors
instance.interceptors.request.use((config) => {
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  // config.headers["ContentType"] = "multipart/form-data";
  return config;
});

instance.show_notif = true;
instance.withCredentials = true;

instance.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    if (error.response.status == 401) {
      localStorage.clear();
      window.location.href = "/";
    } else {
      if (instance.show_notif) {
        showNotification({
          title: "Error",
          message: error.response.data.message,
          color: "red",
          // icon: <FaExclamationCircle/>
        });
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
