import { Card, Row, Table } from "react-bootstrap";
import moment from "moment";

const OmcLeadsTable = ({ omcLeadsData, tableContainerRef }) => {
  return (
    <>
      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm "
      >
        <Card.Body className="p-0 pb-4 justify-content-center">
          <div ref={tableContainerRef} className="table-responsive">
            {omcLeadsData.length > 0 ? (
              <Table hover className="user-table min-height">
                <thead>
                  <tr>
                    <th className="border-bottom">ID</th>
                    <th className="border-bottom">Name</th>
                    <th className="border-bottom">Email</th>
                    <th className="border-bottom">Phone Number</th>
                    <th className="border-bottom">Location</th>
                    <th className="border-bottom">Info</th>
                    <th className="border-bottom">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {omcLeadsData.map((lead) => (
                    <tr key={lead.id}>
                      <td text-label="ID">
                        <span className="fw-normal">{lead.id}</span>
                      </td>

                      <td text-label="Name">
                        <span className="fw-normal">{lead.user_name}</span>
                      </td>
                      <td text-label="Email">
                        <span className="fw-normal">{lead.user_email}</span>
                      </td>
                      <td text-label="Phone Number">
                        <span className="fw-normal">{lead.user_phone}</span>
                      </td>
                      <td text-label="location">
                        {lead.user_state ? (
                          <span className="fw-normal">
                            {lead.user_city}, {lead.user_state}
                          </span>
                        ) : (
                          <span className="fw-normal">N/A</span>
                        )}
                      </td>
                      <td text-label="info">
                        <span className="fw-normal">
                          {lead.is_medical
                            ? "Have Medical Card"
                            : lead.is_recreational
                            ? "Does'nt have Medical Card"
                            : ""}
                        </span>
                      </td>

                      <td text-label="Created At">
                        <span className="fw-normal">
                          {moment(lead.created_at).format("DD MMM YYYY")}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Row className="justify-content-center align-item-center text-dark fw-bold p-4">
                {" "}
                No Data Available.....
              </Row>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
export default OmcLeadsTable;
