// libararies
import { Button, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";

// Componenents
import axios from "../../axios";

import { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { loadingSet } from "../../redux/loadingSlice";

import ServerError from "../Login/ServerError";
import LoadingPlaceHolder from "../../reusable/Helper/LoadingPlaceHolder";
import { scrollButton } from "../../components/Helpers/HelperFunctions";
import OmcLeadsTable from "../../components/Table/OmcLeadsTable";

const OmcLeads = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [omcLeadsData, setOMCLeadsData] = useState([]);
  const [omcLeadsError, setOMCLeadsError] = useState(null);
  const tableContainerRef = useRef(null);

  //dispatch
  const dispatch = useDispatch();
  const omcLeadsLoading = useSelector((state) => state.loading.loading);
  //Functions
  const getOMCLeads = async (start_date, end_date) => {
    const formattedStartDate = start_date
      ? `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`
      : "";

    const formattedEndDate = end_date
      ? `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`
      : "";
    dispatch(loadingSet(true));
    try {
      const res = await axios(
        `/medical/card/leads?start_date=${formattedStartDate}&end_date=${formattedEndDate}`
      );
      setOMCLeadsData(res.data.data);
    } catch (err) {
      setOMCLeadsError(err);
    } finally {
      dispatch(loadingSet(false));
    }
  };

  //useEffects
  useEffect(() => {
    getOMCLeads(startDate, endDate);
  }, [startDate, endDate]);

  if (omcLeadsError) {
    return (
      <ServerError
        errorMessage={omcLeadsError.response.data.message || omcLeadsError}
      />
    );
  }
  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);

    getOMCLeads();
  };
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
        <div className="d-block mb-4 mb-md-0">
          <h3>OMC Leads</h3>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"></div>
      </div>

      <div className="table-settings">
        <Row className="align-items-end">
          <Col md={3} xs={4}>
            <Form.Label>
              <h5>Start Date</h5>
            </Form.Label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy/MM/dd"
              className="form-control"
            />
          </Col>
          <Col md={3} xs={4}>
            <Form.Label>
              <h5>End Date</h5>
            </Form.Label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy/MM/dd"
              className="form-control"
              minDate={startDate}
            />
          </Col>
          <Col md={3}>
            <Button
              variant="outline-danger"
              className="fontweigh-500 mt-3 ms-2"
              onClick={clearFilters}
            >
              Clear filters
            </Button>
          </Col>
        </Row>
        <Row
          className={`justify-content-end align-items-center ${
            omcLeadsData.length ? "mb-0" : "mb-4"
          }`}
        >
          {omcLeadsData.length ? scrollButton(tableContainerRef, 800) : ""}
        </Row>
      </div>

      {omcLeadsLoading ? (
        <LoadingPlaceHolder />
      ) : (
        <OmcLeadsTable
          omcLeadsData={omcLeadsData}
          tableContainerRef={tableContainerRef}
        />
      )}
    </>
  );
};
export default OmcLeads;
