import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const FooterCard = ({ cardTitle, cardTextArr }) => {
  const icon = faArrowAltCircleRight;
  const clickHandler = (e, cardText) => {
    window.location.href = `${cardText.preState}:${cardText.state}`;
    e.preventDefault();
  };

  return (
    <Card className="footer-card ">
      <Card.Title className="fw-bolder fs-6">{cardTitle}</Card.Title>
      <Card.Body className="p-0">
        {cardTextArr.map((cardText) => (
          <Card.Text key={cardText.state}>
            <Link
              className="fw-bold fs-7 link-text"
              to={{
                pathname: cardText.link,
                state: {
                  type: cardText?.type,
                  lat: cardText?.lat,
                  long: cardText?.long,
                },
              }}
              onClick={(e) =>
                cardText.link === "#" && clickHandler(e, cardText)
              }
            >
              <FontAwesomeIcon icon={cardText.icon || icon} /> {cardText.state}
            </Link>
          </Card.Text>
        ))}
      </Card.Body>
    </Card>
  );
};

export default FooterCard;
