import { Accordion, Container } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import ServerError from "../../Login/ServerError";
import { useEffect } from "react";
import axios from "../../../axios";
import { useDispatch } from "react-redux";
import { loadingSet } from "../../../redux/loadingSlice";
import LoadingPlaceHolder from "../../../reusable/Helper/LoadingPlaceHolder";

const FAQPage = () => {
  const dispatch = useDispatch();
  // Functions
  const fetchFaq = async () => {
    const res = await axios(`/get/faqs`);
    return res.data.data;
  };

  const {
    data: faqs = [],
    isLoading: faqLoading,
    error: faqError,
  } = useQuery({
    queryKey: ["faqs"],
    queryFn: fetchFaq,
  });

  // useEffects
  useEffect(() => {
    dispatch(loadingSet(faqLoading));
  }, [faqLoading, dispatch]);

  if (faqError) {
    return (
      <ServerError
        errorMessage={faqError?.response?.data?.message || faqError}
      />
    );
  }
  return (
    <Container className="section-container my-5 w-75 footer-accordion">
      <h2 className="text-center fs-4 fw-bold mb-4">
        Frequently Asked Questions (FAQs)
      </h2>
      <Accordion defaultActiveKey="-1">
        {faqs.map((faq, index) => (
          <Accordion.Item
            eventKey={index.toString()}
            key={faq.id}
            className="mb-2"
          >
            <Accordion.Header>
              <p className="fw-bold m-0">
                Q{index + 1} {faq.question}
              </p>
            </Accordion.Header>
            <Accordion.Body>
              <p>{faq.answer}</p>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      {faqLoading && <LoadingPlaceHolder />}
    </Container>
  );
};
export default FAQPage;
