import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  Row,
  Table,
} from "react-bootstrap";
import CustomToast from "../../reusable/CustomToast";
import { useEffect, useState } from "react";

//axios
import axios from "../../axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisH,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import { useDispatch } from "react-redux";
import { loadingSet } from "../../redux/loadingSlice";
import AddEditFaq from "../../pages/Modals/AddEditFaq";

const FaqTable = ({ faqs, refetchFaq }) => {
  //States
  //customtoast states
  const [showToast, setShowToast] = useState(false);
  const [toastBody, setToastBody] = useState("");
  const [success, setSuccess] = useState(true);

  //Add Edit Modal states
  const [showModal, setShowModal] = useState(false);
  const [faqData, setFaqData] = useState({});

  //loading
  const [loading, setLoading] = useState(false);

  //dispatch
  const dispatch = useDispatch();
  //Functions
  const deleteHandler = async (id) => {
    dispatch(loadingSet(true));
    try {
      const res = await axios({
        method: "delete",
        url: `/faq/delete/${id}`,
      });

      setShowToast(true);
      setToastBody(res.data.message);
      setSuccess(true);
      refetchFaq();
    } catch (err) {
      setShowToast(true);
      setToastBody(err.response.data.message);
      setSuccess(false);
    } finally {
      dispatch(loadingSet(false));
    }
  };

  return (
    <>
      <Card
        border="light"
        className="table-wrapper table-responsive shadow-sm "
      >
        <Card.Body className="p-0 pb-4 justify-content-center">
          {faqs.length > 0 ? (
            <Table responsive hover className="user-table min-height">
              <thead>
                <tr>
                  <th className="border-bottom">Id</th>

                  <th className="border-bottom">Question</th>
                  <th className="border-bottom">Answer</th>
                  <th className="border-bottom">Status</th>
                  <th className="border-bottom">Action</th>
                </tr>
              </thead>
              <tbody>
                {faqs.map((faq) => (
                  <tr key={faq.id}>
                    <td text-label="ID">
                      <span className="fw-normal">{faq.id}</span>
                    </td>

                    <td text-label="Rating">
                      <span className="fw-normal">{faq.question}</span>
                    </td>
                    <td text-label="Comment">
                      <span className="fw-normal">{faq.answer}</span>
                    </td>

                    <td text-label="Status">
                      <span className="fw-normal">
                        {faq.status ? "Yes" : "No"}
                      </span>
                    </td>

                    <td>
                      <td>
                        <Dropdown as={ButtonGroup} drop="down-centered">
                          <Dropdown.Toggle
                            as={Button}
                            split
                            variant="link"
                            className="text-dark m-0 p-0"
                          >
                            <span className="icon icon-sm">
                              <FontAwesomeIcon
                                icon={faEllipsisH}
                                className="icon-dark"
                              />
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setShowModal(true);
                                setFaqData({
                                  id: faq.id,
                                  question: faq.question,

                                  answer: faq.answer,

                                  status: faq.status,
                                });
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} className="me-2" />{" "}
                              Edit
                            </Dropdown.Item>

                            <Dropdown.Item
                              className="text-danger"
                              onClick={() => deleteHandler(faq.id)}
                            >
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="me-2"
                              />{" "}
                              Remove
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Row className="justify-content-center align-item-center text-dark fw-bold p-4">
              {" "}
              No Data Available.....
            </Row>
          )}
        </Card.Body>
      </Card>
      {showModal && (
        <AddEditFaq
          show={showModal}
          handleClose={() => {
            setShowModal(false);
            setFaqData({});
          }}
          faqData={faqData}
          type="edit"
          refetchFaq={refetchFaq}
        />
      )}

      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </>
  );
};
export default FaqTable;
