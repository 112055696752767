import moment from "moment-timezone";
import { Row, Col, Image } from "react-bootstrap";
import logo from "../../../assets/img/logo.png";
import "./index.css";
import FooterCard from "../../../reusable/Cards/FooterCard";
import { useSelector } from "react-redux";

import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../../routes";
import { useMemo } from "react";
import { Link } from "react-router-dom";

export default () => {
  const currentYear = moment().get("year");
  const data = useSelector((state) => state?.homePage.dispByState);
  const statesArr = useMemo(() => {
    if (!data?.length) return [];

    return data
      .slice() // create a shallow copy
      .sort((a, b) => b.dispensaries_count - a.dispensaries_count) // sort the copy
      .slice(0, 10) // take top 10
      .map((item) => ({
        state: item.state_name,
        lat: Number(item.latitude),
        long: Number(item.longitude),
        link: `${
          Routes.Explore.path
        }/Storefronts/state=${item.state_name.replaceAll(" ", "-")}`,
      }));
  }, [data]);

  const menuArr = useMemo(
    () => [
      {
        state: "Storefronts",
        link: `${Routes.Explore.path}/Storefronts`,
      },
      {
        state: "Deliveries",
        link: `${Routes.Explore.path}/Deliveries`,
      },
      {
        state: "Store & Delivery",
        link: `${Routes.Explore.path}/Store&Deliveries`,
      },
      { state: "Blogs", link: `${Routes.blogs.path}` },
      // { state: "Blogs", link: `${Routes.blogs.path}`, type: "posts" },
    ],
    []
  );
  const quickLinkArr = useMemo(
    () => [
      {
        state: "Terms of use ",
        link: Routes.termsOfUSe.path,
      },
      {
        state: "Privacy Policy",
        link: Routes.PrivacyPolicy.path,
      },
      {
        state: "FAQ",
        link: Routes.faqPage.path,
      },
    ],
    []
  );

  const contactArr = useMemo(
    () => [
      {
        state: "contact@weedtome.com",
        icon: faEnvelope,
        link: "#",
        preState: "mailto",
      },
      { state: "(888) 988-8420", icon: faPhoneAlt, link: "#", preState: "tel" },
    ],
    []
  );

  return (
    <div>
      <hr className="top-line m-0" />

      <Row className="m-6">
        <Col className="mb-4 pe-5" lg={3} md={4} sm={6}>
          <Link to={Routes.Home.path}>
            <Image src={logo} alt="logo" />
          </Link>
          <p className="text-align-center mt-4 fontweigh-500 fs-7">
            An inclusive community assisting medical and recreational marijuana
            lovers in discovering top-notch dispensaries nearby for purchasing
            their favorite products.
          </p>
        </Col>

        <Col className="mb-6" lg={2} md={4} sm={6}>
          <FooterCard cardTitle="Dispensaries In" cardTextArr={statesArr} />
        </Col>
        <Col className="mb-6" lg={2} md={4} sm={6}>
          <FooterCard cardTitle="Listing Menu" cardTextArr={menuArr} />
        </Col>
        <Col className="mb-6" lg={2} md={4} sm={6}>
          <FooterCard cardTitle="Quick Links" cardTextArr={quickLinkArr} />
        </Col>
        <Col className="mb-6" lg={3} md={4} sm={6}>
          <FooterCard cardTitle="Contact Us" cardTextArr={contactArr} />
        </Col>
      </Row>

      <Row className="justify-content-center bg-dark text-light p-2 w-100">
        Copyright © {`WeedToMe ${currentYear} | All Rights Reserved`}
      </Row>
    </div>
  );
};
